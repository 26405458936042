<template>
  <div class="relative flex flex-col">
    <client-only>
      <template #fallback>
        <SkeletonsLanding></SkeletonsLanding>
      </template>

      <Transition name="fade" mode="out-in">
        <InitialBackdrop v-if="'initial' in route.query"></InitialBackdrop>
        <template v-else>
          <PageBuilder
            v-if="layout && !isLoading"
            :layout="layout"
          ></PageBuilder>
        </template>
      </Transition>
    </client-only>
  </div>
</template>

<script setup>
const timer = ref();
const route = useRoute();

onBeforeRouteLeave(() => {
  clearTimeout(timer.value);
});

onBeforeMount(async () => {
  await useFmaSdk().logPageNav(usePageId().ADULT__HOME);
});

onMounted(async () => {
  await useFmaSdk().loaded();
});

onBeforeUnmount(() => {
  timer.value && clearTimeout(timer.value);
  useFmaSdk().terminate();
});
// Fetch Layout
const { data: layout, pending: isLoading } = await useAsyncData(
  "layout",
  async () => {
    return await fetchLayout("/api/biz/config/v1/carousels");
  },
  {
    server: false,
  }
);

if ("initial" in route.query) {
  timer.value = setTimeout(() => {
    timer.value && clearTimeout(timer.value);
    navigateTo({
      path: route.path,
      replace: true,
    });
  }, 3000);
}
</script>

<script>
export default { name: "Browse" };
</script>
